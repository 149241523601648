<template>
    <div class="container">
        <h1 class="text text-title text-center text-danger">{{ localize.title }}</h1>
        <p class="text text-center padding">{{ localize.description }}</p>
    </div>
</template>

<script>
import Localize from '@localize'

export default {
    computed: {
        localize () {
            return Localize.notFound()
        }
    }
}
</script>

<style lang="sass">
.text
    font-family: $font-family
    font-size: 1.3rem
    &-center
        text-align: center
    &-title
        font-weight: 600
        font-size: 2rem
    &-danger
        color: $color-error
</style>
